<template>
    <modal ref="modalReferidosClientes" titulo="Referidos de clientes" no-aceptar no-cancelar tamano="modal-xl">
        <cargando v-if="cargando" />
        <div class="row mx-0 py-3" />
        <div class="row mx-0 align-items-center">
            <img v-if="!model.foto" src="https://e00-marca.uecdn.es/assets/multimedia/imagenes/2020/06/04/15912219730543.jpg" class="rounded-circle border obj-cover" width="36" height="36" />
            <img v-else :src="model.foto" class="rounded-circle border obj-cover" width="36" height="36" />
            <p class="col-auto text-general f-18 f-500">
                {{ model.cliente }}
            </p>
            <div class="col-auto text-general f-18 f-500 ml-auto">
                <i class="icon-fecha-fin f-24" />
                {{ model.fechas[0] | helper-fecha('DD MMM YYYY') }} - {{ model.fechas[1] | helper-fecha('DD MMM YYYY') }}
            </div>
        </div>
        <div class="row mx-0 mt-2">
            <div class="col-12 px-0">
                <el-table
                :data="referidos"
                header-row-class-name="text-general f-16"
                style="width: 100%"
                >
                    <el-table-column
                    prop="referido"
                    label="Referidos"
                    />
                    <el-table-column
                    label="Fecha de registro"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.fecha_registro | helper-fecha('DD MMM YYYY') }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Último pedido"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.ultimo_pedido | helper-fecha('DD MMM YYYY') }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="total_pedidos"
                    class-name="text-center"
                    label="Pedidos en la fecha"
                    />
                    <el-table-column
                    label="Valor de los pedidos"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ separadorNumero(scope.row.total_pedidos_valor) }}
                            </p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </modal>
</template>

<script>
import Referidos from "../../../services/clientesEstadisticas/referidos";
export default {
    data(){
        return {
            cargando: true,
            referidos: [],
            model: {
                id_cliente: null,
                cliente: null,
                foto: null,
                fechas: [],
            },
        }
    },
    methods: {
        async getReferidosCliente(){
            try {
                let params = {
                    fechas: this.model.fechas,
                };
                const { data } = await Referidos.getReferidosCliente(this.model.id_cliente,params)
                this.referidos = data.referidos;
                this.cargando = false;
            } catch (error){
                this.error_catch(error)
            }
        },

        toggle(id_cliente,cliente,foto,fechas){
            this.model.id_cliente = id_cliente;
            this.model.cliente = cliente;
            this.model.foto = foto;
            this.model.fechas = fechas;
            this.getReferidosCliente();
            this.$refs.modalReferidosClientes.toggle();
        }
    }
}
</script>
